<template>
  <h2>Добавление сайта</h2>
  <form @submit.prevent class="uk-form-stacked">
    <div class="uk-margin">
      <label class="uk-form-label">Название</label>
      <input class="uk-input" type="text" v-model="name">
      <div class="" v-if="nameErrors">
        <span class="uk-text-danger" v-for="error in nameErrors">{{error}}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Ссылка</label>
      <div class="uk-form-controls">
        <input class="uk-input" type="text" v-model="domain">
        <div class="" v-if="domainErrors">
          <span class="uk-text-danger" v-for="error in domainErrors">{{error}}</span>
        </div>
      </div>
    </div>

    <button class="uk-button uk-button-primary" @click="redirectAndCreate">Сохранить</button>
  </form>
</template>

<script lang="ts">
import {defineComponent, toRefs} from "vue";
import Loading                   from '@/components/Loading.vue';
import {useBannerSiteAdd}        from "../../logic/banner-site/banner-site-add";
import {useRouter}               from "vue-router";

export default defineComponent({
  name      : "BannerSizeCreate",
  components: {
    Loading: Loading,
  },
  setup() {
    const {domainErrors, nameErrors, createSite, site} = useBannerSiteAdd();

    const router = useRouter();
    const redirectAndCreate = () => {
      // @ts-ignore
      createSite().then((result: boolean) => result && router.push({name: 'sites'}))
    }

    return {...toRefs(site), domainErrors, nameErrors, createSite, redirectAndCreate};
  },
})
</script>
