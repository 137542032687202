import {ref, reactive}  from 'vue';
import {Api}            from "../../types/api/v1/electrification";
import ApiStoreAction   from "../../services/api/ApiStoreAction";
import BannerSiteUrlLoc from "../../services/api/banner-site/BannerSiteUrlLoc";
import BannerSiteAdd = Api.V1.BannerSiteAdd;


export const useBannerSiteAdd = () => {

    const site = reactive<BannerSiteAdd>({
        domain: '',
        name  : '',
    });


    const store = new ApiStoreAction<BannerSiteAdd>(new BannerSiteUrlLoc());


    const nameErrors   = ref<Array<String>>([]);
    const domainErrors = ref<Array<String>>([]);

    // @ts-ignore
    const setSize = (value: BannerSiteAdd) => site.value = value;

    const createSite = (): Promise<boolean> => {
        return store.create(site).then((data: BannerSiteAdd) => {
            setSize(data);
            return Promise.resolve(true);
        }, (error) => {
            fetchErrors(error.response.data.errors)
            return Promise.resolve(false);
        });
    }


    const fetchErrors = (errors: { domain: Array<string>, name: Array<string> }) => {
        errors.hasOwnProperty('domain') && (domainErrors.value = errors.domain);
        errors.hasOwnProperty('name') && (nameErrors.value = errors.name);
    }

    return {nameErrors, domainErrors, setSize, createSite, site};
};