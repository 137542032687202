
import {defineComponent, toRefs} from "vue";
import Loading                   from '@/components/Loading.vue';
import {useBannerSiteAdd}        from "../../logic/banner-site/banner-site-add";
import {useRouter}               from "vue-router";

export default defineComponent({
  name      : "BannerSizeCreate",
  components: {
    Loading: Loading,
  },
  setup() {
    const {domainErrors, nameErrors, createSite, site} = useBannerSiteAdd();

    const router = useRouter();
    const redirectAndCreate = () => {
      // @ts-ignore
      createSite().then((result: boolean) => result && router.push({name: 'sites'}))
    }

    return {...toRefs(site), domainErrors, nameErrors, createSite, redirectAndCreate};
  },
})
